import React, { useState } from "react";
import "./Cart.scss";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useDispatch, useSelector } from "react-redux";
import { removeItem, resetCart } from "../../redux/cartReducer";
import { loadStripe } from "@stripe/stripe-js";
import { makeRequest } from "../../makeRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import { Link } from "react-router-dom";

const Cart = () => {
  const products = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();
  const [close, setClose] = useState(false);
   const [isCheckout, setIsCheckout] = useState(false);

  // console.log("products", products);

  const delivaryAmout = 20;
  const delivaryAmoutLess = 10;
  const noDelivaryAmout = 0;

  const totalPrice = () => {
    let total = 0;
    products.forEach((item) => (total += item.quantity * item.price));
    return total.toFixed(2);
  };

  {
    /*
  const dicountPrice = () =>{
    const discountRate = (netOder * 15) / 100;
    return discountRate.toFixed(2)
  }
*/
  }

  const netOder = Math.min(totalPrice());

  // Delivary cost calculation
  const withDelivaryCost = () => {
     if (netOder < 50) {
       const netTotal = netOder + delivaryAmout;
       return netTotal.toFixed(2);
     }
     if (netOder >= 50 && netOder < 75) {
       const netTotal = netOder + delivaryAmoutLess;
       return netTotal.toFixed(2);
     }
     if (netOder >= 75) {
       const netTotal = netOder + noDelivaryAmout;
       return netTotal.toFixed(2);
     } else {
       console.log("all good");
     }
  };

  const finalCost = withDelivaryCost()

 

 

  //Delivary cost msg....

  {
    /* const shipingCost =  () => {
    let stotal = 0
    const shpCost = 15
    if (netOder < 50) {
       products.forEach((item) => (stotal += item.quantity ), 0);
       const shipOrgPrice = shpCost / stotal 
      return shipOrgPrice;
    } if (netOder > 50) {
      const shipOrgPrice = 0;
      return shipOrgPrice;
    }
  };


  const shipping_rate = shipingCost();
  console.log(shipping_rate, "Shipping cost");*/
  }

  const addShippingcostwith = () => {
    if (netOder < 50) {
      const shiCost = true;
      return shiCost;
    }
  };

  const withShippingCost = addShippingcostwith();
  // console.log(withShippingCost, "testing price with 15 AED");

  const addShippingcostwithOut = () => {
    if (netOder > 50) {
      const shiCost = true;
      return shiCost;
    }
  };

  const withOutShippingCost = addShippingcostwithOut();
  // console.log(withOutShippingCost, "testing price with 0 AED");

  let FinalNetPrice = withDelivaryCost();

  // console.log(FinalNetPrice, "final price for checkuot");
  // Messeage

  const massageDisplay = () => {
    if (netOder < 75) {
      let value = 75 - netOder;
      return `Add AED ${value.toFixed(
        2
      )} of eligible items to your order to qualify for FREE Shipping. `;
    } else {
      return "🥳  Woo.. hoo.. you eligible for free delivery 🚚 ";
    }
  };

  

  // Chnage color
  const deliveryChargeClass = netOder > 50 ? "delivery-charge" : netOder < 50;

   const deliveryChargeClassBitween =
     netOder > 50 || netOder < 75 ? "delivery-charge" : "";
  //Hiding Total value
  const inital = netOder === 0 ? "hide" : "";
  //Hiding

  //const finalPrice = withDelivaryCost();

  // const stripePromise = loadStripe(
  //   "pk_live_51NZq6pLFX6mtMcpcmmo6KvEu2tURioX0Ip3uTWUs0eZGPLe3qVUpyXcR3H27pfrkt7PQ1izb6sK5LTW2FmzEF4Sp00jGDUWGNH"
  // );

  // const apiURL = "http://localhost:4343";



  const makePayment = async () => {
    const stripe = await loadStripe(
      "pk_test_51NdnknKd2qy2P3URf4qvbTs4S4JIjCuVu2ki2c3Cw7E9MRSrWpNbQWT3xvke6OHppDgp4LqrzV5p94vLJslxcZGV00SqX0bJLr"
    );

    const body = {
      products,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    const brand = "snacksfactory";

    const response = await axios.post(
      `/create-checkout-session`,
      body,brand,
      { headers: headers }
    );

    const session = response.data;
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.log(result.error.message);
    }
  };

 

  // const handlePayment = async () => {
  //   try {
  //     const stripe = await stripePromise;
  //     const res = await makeRequest.post("/orders", {
  //       products,
  //       withShippingCost,
  //       withOutShippingCost,
  //     });

  //     await stripe.redirectToCheckout({
  //       sessionId: res.data.stripeSession.id,
  //     });
  //   } catch (err) {
  //     console.log(err, "Stripe not get connect...");
  //   }
  // };

   const handleCheckout = () => {
     setIsCheckout(true);
   };

  return (
    <>
      {!isCheckout && (
        <div className="cart">
          {/*
        <div className="close">
          <button onClick={() => setClose(true)}>
            <CancelIcon className="close_icon" />
          </button>
        </div> */}

          <div className="cart_wrap" style={{ padding: "10px" }}>
            <div className="freeDel">
              <span>
                * Order value below <strong>AED 50</strong> delivary charges{" "}
                <strong>AED 20</strong> <br />* Order value{" "}
                <strong>AED 50 - AED 75</strong> delivary charges{" "}
                <strong>AED 10</strong> <br />* Order value above{" "}
                <strong>AED 75</strong> free home delivary
              </span>
            </div>
            <h2 className="cart-head">Product in your cart</h2>

            {products?.map((item, i) => (
              <div className="item" key={i}>
                <img src={process.env.REACT_APP_UPLOAD_URL + item.img} alt="" />
                <div className="details">
                  <h5>{item.title}</h5>
                  <p>{item.desc?.substring(0, 50)}</p>
                  <div className="price">
                    <span>
                      Qty {item.quantity} x AED {item.price}
                    </span>
                  </div>
                </div>
                <DeleteOutlinedIcon
                  className="delete"
                  onClick={() => dispatch(removeItem(item.id))}
                />
              </div>
            ))}
            <div className="total">
              <span>Products Total</span>
              <span>AED {totalPrice()}</span>
            </div>

            {/*
          <div className="total">
            <span style={{ color: "orange" }}> 15% Discount </span>
            <span style={{ color: "orange" }}>AED - {dicountPrice()}</span>
          </div>

          */}

            <div className="total">
              <span>Delivery Charges</span>
              {netOder < 50 ? (
                <p>AED 20.00</p>
              ) : netOder >= 50 && netOder < 75 ? (
                <p>AED 10.00</p>
              ) : (
                <p>Free</p>
              )}
            </div>

            <div className="sub_total">
              <span>SUBTOTAL</span>
              <div className={`${inital}`}>
                <span>AED {FinalNetPrice}</span>
              </div>
            </div>
            <div className="delivaryMsg">
              <span>{massageDisplay()}</span>
            </div>
            <Link
              to={{
                pathname: "/check_out",
                state: { products, finalCost },
              }}
            >
              <button className="checkout" onClick={handleCheckout}>
                PROCEED TO CHECKOUT
              </button>
            </Link>
            <span className="reset" onClick={() => dispatch(resetCart())}>
              Reset Cart
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
