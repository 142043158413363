import React from 'react'
import "./gourmet.scss";
import bites from "../../img/bk2.png";
import { Link } from "react-router-dom";


const Gourmet = () => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
  return (
    <div className="max-width">
      <div
        className="brandInfo"
        style={{
          backgroundImage: `url(${bites})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="_wegan">
          <img src={require("../../img/g_logo2.png")} alt="" />
        </div>
        <div className="des">
          <p>
            Chocodate Gourmet, where luxury meets indulgence in every bite.{" "}
            <br />
            Crafted with the finest ingredients, each Chocodate Gourmet piece
            encapsulates a rich medjool date filled with a whole almond and
            enrobed in premium Swiss chocolate. The result is a harmonious blend
            of sweet, chewy date, crunchy almond, and velvety chocolate that
            delights the senses with every taste. <br />
            Perfect for those who appreciate the finer things in life, <br />
            <br />
            <strong>
              Chocodate Gourmet promises a luxurious chocolate experience that
              is simply irresistible.
            </strong>
          </p>
        </div>
        <div className="gom_brandInfo_wrp">
          <Link to={"/product/99"} onClick={scrollToTop}>
            <div
              className="item"
              style={{
                boxShadow:
                  "0px 5px 10px -3px #6f3f91,0px 5px 10px -3px #6f3f91",
              }}
            >
              <div className="item_img">
                <img src={require("../../img/g_01.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{
                  borderTop: "2px solid #6f3f91",
                  backgroundColor: "#6f3f91",
                }}
              >
                <button>Order Now</button>
              </div>
            </div>
          </Link>

          <Link to={"/product/95"} onClick={scrollToTop}>
            <div
              className="item"
              style={{
                boxShadow:
                  "0px 5px 10px -3px #0589c5,0px 5px 10px -3px #0589c5",
              }}
            >
              <div className="item_img">
                <img src={require("../../img/g_02.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{
                  borderTop: "2px solid #0589c5",
                  backgroundColor: "#0589c5",
                }}
              >
                <button>Order Now</button>
              </div>
            </div>
          </Link>

          <Link to={"/product/98"} onClick={scrollToTop}>
            <div
              className="item"
              style={{
                boxShadow:
                  "0px 5px 10px -3px #00a184,0px 5px 10px -3px #00a184",
              }}
            >
              <div className="item_img">
                <img src={require("../../img/g_03.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{
                  borderTop: "2px solid #00a184",
                  backgroundColor: "#00a184",
                }}
              >
                <button>Order Now</button>
              </div>
            </div>
          </Link>
          <Link to={"/product/104"} onClick={scrollToTop}>
            <div
              className="item"
              style={{
                boxShadow:
                  "0px 5px 10px -3px #edbc27,0px 5px 10px -3px #edbc27",
              }}
            >
              <div className="item_img">
                <img src={require("../../img/g_04.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{
                  borderTop: "2px solid #edbc27",
                  backgroundColor: "#edbc27",
                }}
              >
                <button>Order Now</button>
              </div>
            </div>{" "}
          </Link>

          <Link to={"/product/103"} onClick={scrollToTop}>
            <div
              className="item"
              style={{
                boxShadow:
                  "0px 5px 10px -3px #e26640,0px 5px 10px -3px #e26640",
              }}
            >
              <div className="item_img">
                <img src={require("../../img/g_05.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{
                  borderTop: "2px solid #e26640",
                  backgroundColor: "#e26640",
                }}
              >
                <button>Order Now</button>
              </div>
            </div>
          </Link>

          <Link to={"/product/96"} onClick={scrollToTop}>
            <div
              className="item"
              style={{
                boxShadow:
                  "0px 5px 10px -3px #65b046,0px 5px 10px -3px #65b046",
              }}
            >
              <div className="item_img">
                <img src={require("../../img/g_06.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{
                  borderTop: "2px solid #65b046",
                  backgroundColor: "#65b046",
                }}
              >
                <button>Order Now</button>
              </div>
            </div>
          </Link>
        </div>
        <div className="__symbol">
          <img src={require("../../img/icons.png")} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Gourmet
